<template>
  <div>
    <operations-detail-component-global
      :sApiGetOC="sApiGetOC"
      :aHeadersRawMaterials="aHeadersRawMaterials"
      :sTypeItem="1"
      sBackHref="TransationDetail"
      sTextDetail=""
      setDialog="setDialogConfirmationOfPaid"
      sTextBtnAdd=""
      :bAdminPermissions="bAdminOPGeneral"
      :itemsBreadcrumbs="itemsBreadcrumbs"
      :toProvider="toProvider"
      :bToProvider="false"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemsBreadcrumbs: [],
      toProvider: "",
      sApiGetOC: "",
      aHeadersRawMaterials: [
        {
          text: "#",
          sortable: false,
          value: "dNumTransation",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Código de materia",
          sortable: false,
          value: "sCodeRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Materia",
          sortable: false,
          value: "sNameRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Responsable",
          sortable: false,
          value: "sManagerRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Peso",
          sortable: false,
          value: "dWeightRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Merma",
          sortable: false,
          value: "dUnprocessedWeight",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Precio unitario",
          sortable: false,
          value: "dUnitPriceRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Subtotal",
          sortable: false,
          value: "dSubtotalRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Acciones",
          value: "accions",
          align: "center",
          class:
            "global-header-table global-header-accions global-col-header-accion",
          cellClass:
            "global-body-table global-body-accions global-col-body-accion",
        },
      ],
    };
  },
  beforeMount() {
    this.setNameBreadcrumbs();
    this.setApiGetOC();
  },
  methods: {
    setNameBreadcrumbs() {
      let aBreadcrumbs = [
        {
          text: "Cuentas",
          disabled: false,
          to: { name: "Account" },
        },
        {
          text: "Detalle de cuenta financiera",
          link: true,
          exact: true,
          disabled: false,
          to: { name: "AccountDetail" },
          params: { id: this.$route.params.id },
        },
        {
          text: "Detalle de una transacción",
          link: true,
          exact: true,
          disabled: false,
          to: { name: "TransationDetail" },
          params: { id: this.$route.params.idTransation },
        },
        {
          text: "Detalle de una operación",
          link: true,
          exact: true,
          disabled: true,
        },
      ];
      this.itemsBreadcrumbs = aBreadcrumbs;
      // this.toProvider = `/home/provider-detail/${this.$route.params.id}/op-detail-provider/${this.$route.params.idOP}/user-profile-detail-provider`;
    },
    setApiGetOC() {
      this.sApiGetOC = `${URI}/api/sp/v1/orders/general/`;
    },
  },
  computed: {
    bShowOPGeneral() {
      return this.$store.state.bShowOPGeneral;
    },
    bAdminOPGeneral() {
      return this.$store.state.bAdminOPGeneral;
    },
    sTabProviderOrPerson() {
      return this.$store.state.sTabPosition;
    },
  },
};
</script>

<style></style>
